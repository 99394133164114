@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Fonts */
  @font-face {
    font-family: 'Chakra Petch';
    src: local('Chakra Petch'), url('./assets/fonts/ChakraPetch-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Chakra Petch';
    src: local('Chakra Petch'), url('./assets/fonts/ChakraPetch-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Chakra Petch';
    src: local('Chakra Petch'), url('./assets/fonts/ChakraPetch-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Chakra Petch';
    src: local('Chakra Petch'), url('./assets/fonts/ChakraPetch-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px;
  @apply mx-auto;
}
